import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../_common/hooks/reduxHooks';
import { useHelpScout } from '../_common/hooks/help-scout/useHelpScout';
import { useOnboarding } from '../_common/hooks/useOnboarding';
import { fetchCategories } from './reducers/categories';
import { clearConsumers, fetchConsumers } from './reducers/consumers';
import {
  clearProducerProducts,
  fetchProducerProducts
} from './reducers/products';
import { CommandPalette } from '@components/command-palette';
import {
  clearProductRequests,
  setUpProductRequests
} from '_common/reducers/productRequests';
import { clearOrders, fetchOrders } from '_common/reducers/orders';
import { clearMessages, setUpMessages } from '_common/reducers/messages';

export const ProducerLayout = () => {
  const dispatch = useAppDispatch();
  const helpScout = useHelpScout();
  const { accountId, auth } = useAppSelector(({ auth }) => {
    return {
      accountId: auth._id,
      auth: auth
    };
  });
  const { getCurrentOnboardingStep } = useOnboarding(auth);

  const fetchUserData = () => {
    dispatch(fetchOrders({ producerId: accountId }));
    dispatch(fetchProducerProducts(accountId));
    dispatch(fetchCategories());
    dispatch(setUpProductRequests(accountId));
    dispatch(fetchConsumers({ producerId: accountId }));
    dispatch(setUpMessages(accountId));
  };

  useEffect(() => {
    if (accountId) {
      fetchUserData();
    }
    return () => {
      dispatch(clearOrders());
      dispatch(clearProducerProducts());
      dispatch(clearProductRequests());
      dispatch(clearConsumers());
      dispatch(clearMessages());
    };
  }, [accountId]);

  useEffect(() => {
    if (
      accountId &&
      auth.uid &&
      auth._type &&
      auth.loginEmail &&
      auth.name &&
      auth.contactPerson
    ) {
      const incompleteOnboardingStep = getCurrentOnboardingStep();
      helpScout.identify({
        userId: auth.uid,
        accountId: accountId,
        email: auth.loginEmail,
        company: auth.name,
        accountType: 'producer' as const,
        accountCreated: auth._createdAt as string,
        language: auth.roleLang || 'en',
        currentIncompletedOnboardingStep: incompleteOnboardingStep
      });
    }
  }, [accountId, auth.onboardingHistory, auth.onboarding, auth.roleLang]);

  return (
    <>
      <Outlet />
      <CommandPalette />
    </>
  );
};
