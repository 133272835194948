import { useEffect, useState } from 'react';
import { getHelpScoutToken } from '../../../api';
import { OnboardingHistoryKeys } from '../../../types/Producer';
import Logger from '../../../services/Logger';
import { slugifyDate } from '../../../utils/date/format';
import { HELP_SCOUT_MESSAGES } from './messageConfig';
import { BeaconFunction, User, ShowMessageOptions } from './types';
import { HELP_SCOUT_BEACON_KEY } from 'config';

declare global {
  type WindowWithBeacon = Window & {
    Beacon?: BeaconFunction;
  };
}

export const useHelpScout = () => {
  const Beacon = (window as WindowWithBeacon).Beacon;
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    const checkInitialization = async () => {
      if (!Beacon) return false;

      try {
        const info = await Beacon('info');
        const isInit = !!info?.beaconId;
        setIsInitialized(isInit);
        return isInit;
      } catch (error) {
        setIsInitialized(false);
        return false;
      }
    };

    checkInitialization();
  }, [Beacon]);

  const init = async () => {
    if (!Beacon) {
      return false;
    }

    if (!HELP_SCOUT_BEACON_KEY) {
      Logger.warn('Missing HELP_SCOUT_BEACON_KEY in config');
      return false;
    }

    if (isInitialized) {
      Logger.debug('Beacon already initialized');
      return true;
    }

    try {
      await Beacon('config', {
        hideFABOnMobile: true
      });
      await Beacon('init', HELP_SCOUT_BEACON_KEY);
      return true;
    } catch (e) {
      Logger.debug('Beacon init failed:');
      return false;
    }
  };

  const initialize = async () => {
    return init();
  };

  const identifyUser = async (user: User, signature: string) => {
    if (!Beacon) return false;

    try {
      await Beacon('identify', {
        email: user.email,
        company: user.company,
        'account-type': user.accountType,
        'account-created': slugifyDate(user.accountCreated),
        'current-incompleted-onboarding-step':
          user.currentIncompletedOnboardingStep
            ? user.currentIncompletedOnboardingStep
            : 'completed',
        language: user.language,
        signature
      });
      // Hack to update users on switch account
      Beacon('open');
      Beacon('close');
      setIsInitialized(true);
      return true;
    } catch (error) {
      Logger.error(
        new Error(`Failed to identify Beacon user with error ${error}`)
      );
      setIsInitialized(false);
      return false;
    }
  };

  const identify = async (user: User) => {
    if (!Beacon) {
      return;
    }

    if (!isInitialized) {
      const initSuccess = await initialize();
      if (!initSuccess) {
        Logger.error(new Error('Failed to initialize Beacon'));
        return;
      }
    }

    try {
      const { signature } = await getHelpScoutToken({
        userId: user.userId,
        accountId: user.accountId,
        email: user.email
      });

      if (!signature) {
        Logger.info('Failed to get signature from API');
        return;
      }

      const identified = await identifyUser(user, signature);
      if (!identified) {
        Logger.error(
          new Error('Failed to identify user with Help Scout Beacon')
        );
        setIsInitialized(false);
      }
    } catch (error) {
      Logger.error(
        new Error(
          `Failed to get Help Scout token or identify with error ${error}`
        )
      );
      setIsInitialized(false);
    }
  };

  const showMessage = (messageId: string, options?: ShowMessageOptions) => {
    if (!Beacon) {
      return;
    }

    try {
      Beacon('show-message', messageId, options);
    } catch (error) {
      Logger.warn(`Error showing message: ${error}`);
    }
  };

  const showMessageForOnboardingStep = (
    accountType: 'producer' | 'buyer',
    step: keyof typeof OnboardingHistoryKeys | 'hasSeenIntroScreen'
  ) => {
    if (!Beacon) {
      return;
    }

    const messageId =
      accountType === 'producer'
        ? HELP_SCOUT_MESSAGES.producer[
            step as keyof typeof OnboardingHistoryKeys
          ]
        : HELP_SCOUT_MESSAGES.buyer[step as 'hasSeenIntroScreen'];

    if (messageId) {
      showMessage(messageId, {
        force: true
      });
    } else {
      Logger.info(`ℹ️ No message configured for step: ${step}`);
    }
  };

  const open = () => {
    if (!Beacon) {
      return;
    }
    Beacon('open');
  };

  return {
    identify,
    open,
    showMessage,
    showMessageForOnboardingStep,
    isInitialized
  };
};
