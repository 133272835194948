import {
  OnboardingHistoryKeys,
  ProducerOnboarding
} from '../../types/Producer';
import { AuthState } from '../reducers/auth';
import { type Onboarding as ConsumerOnboarding } from 'utils/role';

export type OnboardingStepKeys =
  | keyof typeof OnboardingHistoryKeys
  | keyof ConsumerOnboarding;

export const useOnboarding = (auth: AuthState) => {
  const getProducerOnboardingStep = (
    onboardingStatus: Omit<ProducerOnboarding, 'accountType'>
  ): OnboardingStepKeys | undefined => {
    return Object.values(OnboardingHistoryKeys).find(
      step =>
        step !== OnboardingHistoryKeys.setUpInitialDeliveryRoute &&
        Boolean(
          onboardingStatus[step as keyof typeof OnboardingHistoryKeys]
        ) === false
    ) as OnboardingStepKeys | undefined;
  };

  const getConsumerOnboardingStep = (
    onboardingStatus: ConsumerOnboarding
  ): OnboardingStepKeys | undefined => {
    return onboardingStatus.hasSeenIntroScreen === undefined
      ? 'hasSeenIntroScreen'
      : undefined;
  };

  const getCurrentOnboardingStep = (): OnboardingStepKeys | undefined => {
    try {
      if (auth._type === 'producers' && auth.onboardingHistory) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { accountType, ...onboardingStatus } = auth.onboardingHistory;
        return getProducerOnboardingStep(onboardingStatus);
      }
      if (auth._type === 'consumers' && auth.onboarding) {
        return getConsumerOnboardingStep(auth.onboarding);
      }
    } catch (error) {
      console.error('Error determining onboarding step:', error);
    }
    return undefined;
  };

  return {
    getCurrentOnboardingStep
  };
};
