export enum FeatureFlags {
  '' = '',
  DELIVERY_REPORT_BETA = 'DELIVERY_REPORT_BETA'
}

export const hasUserFeatureFlag = (
  featureFlag: FeatureFlags,
  userFeatureFlags: string[] | undefined
) => {
  return userFeatureFlags && userFeatureFlags.includes(featureFlag);
};
