import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../_common/hooks/reduxHooks';
import { useHelpScout } from '../_common/hooks/help-scout/useHelpScout';
import { useOnboarding } from '../_common/hooks/useOnboarding';
import { clearMessages, setUpMessages } from '_common/reducers/messages';
import { clearOrders, fetchOrders } from '_common/reducers/orders';
import {
  clearProductRequests,
  setUpProductRequests
} from '_common/reducers/productRequests';
import { clearProducers, fetchProducers } from '_consumer/reducers/producers';
import {
  clearProductsForSale,
  fetchProductsForSale
} from '_consumer/reducers/productsForSale';
import {
  clearStatistics,
  fetchStatistics
} from '_consumer/reducers/statistics';
import { CommandPalette } from '@components/command-palette';

export const ConsumerLayout = () => {
  const helpScout = useHelpScout();
  const { auth, accountId, distributionAreaId, roleLang, isMeyersAccount } =
    useAppSelector(({ auth }) => {
      return {
        auth,
        accountId: auth._id,
        distributionAreaId: auth.distributionAreaId,
        roleLang: auth.roleLang,
        isMeyersAccount: auth.isMeyersAccount
      };
    });
  const dispatch = useAppDispatch();
  const { getCurrentOnboardingStep } = useOnboarding(auth);

  const fetchUserData = () => {
    dispatch(fetchOrders({ consumerId: accountId }) as any);
    if (accountId && distributionAreaId && roleLang) {
      dispatch(
        fetchProductsForSale({
          userId: accountId,
          distributionAreaId,
          roleLang,
          isMeyersAccount
        }) as any
      );
    }
    if (distributionAreaId) {
      dispatch(fetchProducers({ distributionAreaId, isMeyersAccount }) as any);
    }
    dispatch(setUpProductRequests(accountId) as any);
    dispatch(setUpMessages(accountId) as any);
    if (accountId) {
      dispatch(fetchStatistics({ consumerId: accountId }) as any);
    }
  };

  useEffect(() => {
    fetchUserData();
    return () => {
      dispatch(clearOrders());
      dispatch(clearProductsForSale());
      dispatch(clearProducers());
      dispatch(clearProductRequests());
      dispatch(clearMessages());
      dispatch(clearStatistics());
    };
  }, [accountId]);

  useEffect(() => {
    if (
      accountId &&
      auth.uid &&
      auth._type &&
      auth.loginEmail &&
      auth.name &&
      auth.contactPerson
    ) {
      const incompleteOnboardingStep = getCurrentOnboardingStep();
      helpScout.identify({
        userId: auth.uid,
        accountId: accountId,
        email: auth.loginEmail,
        company: auth.name,
        accountType: 'buyer' as const,
        accountCreated: auth._createdAt as string,
        language: auth.roleLang || 'en',
        currentIncompletedOnboardingStep: incompleteOnboardingStep
      });
    }
  }, [accountId, auth.onboardingHistory, auth.onboarding, auth.roleLang]);

  return (
    <>
      <Outlet />
      <CommandPalette />
    </>
  );
};
