export const gitCommitRef = (import.meta.env.VITE_COMMIT_REF ||
  'development') as string;
export const environment = (import.meta.env.VITE_ENV ||
  'development') as string;
export const dataset = {
  production: 'production',
  prodStaging: 'production',
  test: 'staging',
  development: 'development'
}[environment] as string;

export const baseUrl =
  import.meta.env.VITE_API_URL || 'http://localhost:3000/api';

const searchIndexPrefix = {
  production: 'prod_',
  prodStaging: 'prod_',
  test: 'test_',
  development: 'dev_'
}[environment];

export const productsSearchIndex = `${searchIndexPrefix}PRODUCTS`;

export const producersSearchIndex = `${searchIndexPrefix}PRODUCERS`;

export const defaultDistributionAreaId = {
  production: '22d04fb7-3be8-4eb7-ab91-d542e8cb340a',
  prodStaging: '22d04fb7-3be8-4eb7-ab91-d542e8cb340a',
  test: 'oRIVsNwDQfifK9rvO1mI0H',
  development: '1468a87e-8223-4abd-beaa-09ce7cec8a38'
}[environment];

export const AUTO_REQUEST_TIMEOUT_MS = 700;

export const PAGE_SIZE = 20;

export const PUBLIC_RELEASE_NOTES_URL =
  'https://helpcenter.dagens.farm/collection/51-updates';

export const ALL_CUSTOMERS = 'ALL_CUSTOMERS';

export const LOG_LEVEL = environment === 'development' ? -1 : 2;

export const HELP_SCOUT_BEACON_KEY = {
  production: '80ac3fa2-f0d1-4bce-96b4-7e30db897033',
  test: '865ab35a-3608-4bf8-a401-79745b46a45d',
  development: '865ab35a-3608-4bf8-a401-79745b46a45d'
}[environment] as string;
